/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import React from "react";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import MediaCard from "components/MediaCard";

/**
 * After Action Review List Item component for displaying a list item with positive/negative check
 *
 * @param props.isPositive: item was achieved
 * @param props.isNegative: item was not achieved
 * @param props.text: text
 * @param props.list: list
 */
function AARListItem(props) {
  let icon;
  let list;

  if (props.isPositive) {
    icon = <DoneIcon color="primary" style={{ fontSize: "2rem" }} />;
  } else if (props.isNegative) {
    icon = <ClearIcon color="error" style={{ fontSize: "2rem" }} />;
  }

  if (props.list) {
    list = (
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.list.map((item, i) => (
            <ListItem key={`${props.text}-${i}`}>
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography variant="body1">{item}</Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ListItem>
        <MediaCard title={props.text} content={list} />
      </ListItem>
    </MuiThemeProvider>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00FF00" }, // positive
    secondary: { main: "#FFFF00" }, // neutral
    error: { main: "#FF0000" }, // negative
  },
  typography: { useNextVariants: true },
});

export default AARListItem;
