/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import XMLData from "./XMLData";
import DialogData from "./DialogData";
import { isObj } from "./DataUtils";

const _addDialog = (data, toDialogsById) => {
  const id = _addEntity(data, toDialogsById);
  DialogData.fromXMLData(data, toDialogsById[id]);
  return id;
};

const _addEntity = (entityData, toEntitiesById) => {
  const id = jsonpath.value(entityData, `$.${XMLData.ATTRS}.ID`);
  toEntitiesById[id] = {};
  return id;
};

class ScenarioData {
  /**
   * Convert task xml
   * to a normalized form of tasks (by id) and skills (by id)
   */

  static async fromXML(xml) {
    const data = await XMLData.toJson(xml);
    const dialogs = jsonpath.query(
      data,
      `$..DialogEntry[?(@.${XMLData.ATTRS}.ID)]`
    );
    return dialogs.reduce(
      (acc, cur) => {
        _addDialog(cur, acc.dialogs);
        return acc;
      },
      { dialogs: {} }
    );
  }

  /**
   * Find the correct choice among the options for a dialog node
   * @param {string} diagId the id of the dialog node
   * @param {object} dialogsById dict of dialog data by id
   * @return {string} the id of the correct choice or null if none
   */
  static findCorrectChoice(diagId, dialogsById) {
    const d = dialogsById[diagId];
    if (!isObj(d)) {
      console.error(`no dialog for id '${diagId}'`);
      return null;
    }
    const links = DialogData.getNextDecisionLinks(d, dialogsById);
    if (!Array.isArray(links)) {
      return null;
    }
    const ix = links.findIndex((linkId, i) => {
      const o = dialogsById[linkId];
      return o && DialogData.getFeedback(o) === "green";
    });
    return ix >= 0 ? links[ix] : null;
  }

  /**
   * Returns TRUE if the given diag node is a scored, player-choice node.
   * The way the data works, many nodes in the diag tree
   * are actually just there to indicate video plays
   * and pass through automatically to next node without a player choice or scoring.
   * @param {string} diagId the id of the dialog node
   * @param {object} dialogsById dict of dialog data by id
   * @return {string} the id of the correct choice or null if none
   */
  static isScoringChoice(diagId, dialogsById) {
    const d = dialogsById[diagId];
    if (!isObj(d)) {
      console.error(`no dialog for id '${diagId}'`);
      return false;
    }
    return DialogData.getSkillIds(d).length > 0;
  }

  /**
   * Returns TRUE if the given diag node is a pass through,
   * e.g. plays a video with no player choice.
   * The way the data works, many nodes in the diag tree
   * are actually just there to indicate video plays
   * and pass through automatically to next node without a player choice or scoring.
   * @param {string} diagId the id of the dialog node
   * @param {object} dialogsById dict of dialog data by id
   * @return {string} the id of the correct choice or null if none
   */
  static isPassThroughNode(diagId, dialogsById) {
    const d = dialogsById[diagId];
    if (!isObj(d)) {
      console.error(`no dialog for id '${diagId}'`);
      return false;
    }
    return DialogData.getFeedback(d) === "white";
  }

  /**
   * For a dialog choice finds any skill penalties the choice avoids
   * that match a list of tracked 'avoids'
   *
   * @param {string} diagId the id of the dialog where the player chooses from
   * @param {string} fromDiagId the id of player's choice
   * @param {object} dialogsById dict of dialog data by id
   * @param {Array<string>} avoidIds list of skill ids to check for
   * @return {Array<string>} the list of skill ids that are contained in `avoidIds` and that the player avoided with their choice
   */
  static findSkillPenaltiesAvoidedByChoice(
    diagId,
    fromDiagId,
    dialogsById,
    avoidIds
  ) {
    const fromDiag = dialogsById[fromDiagId];
    if (!isObj(fromDiag)) {
      console.error(`no dialog for id '${fromDiagId}'`);
      return [];
    }
    const links = DialogData.getNextDecisionLinks(fromDiag, dialogsById);
    if (!Array.isArray(links)) {
      return [];
    }
    return links.reduce((acc, cur) => {
      if (cur === diagId) {
        // this is the choice the player made, so ignore it
        return acc;
      }
      const diagNotChosen = dialogsById[cur];
      if (!isObj(diagNotChosen)) {
        return acc;
      }
      avoidIds.forEach((aid) => {
        if (DialogData.getSkillScore(diagNotChosen, aid, 1.0) < 1.0) {
          // if this Dialog has a score for one of the avoid skills that's < 1.0, add it
          acc.push(aid);
        }
      });
      return acc;
    }, []);
  }
}
export default ScenarioData;
