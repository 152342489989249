/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import List from "@material-ui/core/List";

import AARListItem from "components/AARListItem";
import HeaderBar from "components/HeaderBar";
import NavButton from "components/NavButton";

class AARAvoid extends Component {
  static propTypes = {
    skills_to_avoid: PropTypes.array,
    skills_avoided: PropTypes.array,
  };

  renderItems() {
    const avoid =
      this.props.skills_to_avoid.length > 0
        ? this.renderItem(
            "You should avoid:",
            this.props.skills_to_avoid,
            false,
            true,
            "avoid_did"
          )
        : null;
    const avoided =
      this.props.skills_avoided.length > 0
        ? this.renderItem(
            "You successfully avoided:",
            this.props.skills_avoided,
            true,
            false,
            "avoid_should"
          )
        : null;

    return (
      <div>
        <List component="nav">
          {avoid}
          {avoided}
        </List>
      </div>
    );
  }

  renderItem(text, list, isPositive, isNegative, key) {
    return (
      <AARListItem
        text={text}
        isPositive={isPositive}
        isNegative={isNegative}
        list={list}
        key={key}
      />
    );
  }

  render() {
    const { id } = this.props.match.params;
    const header = this.props.header_hidden ? undefined : (
      <HeaderBar text="Behaviors to Avoid" />
    );

    var avoid =
      this.props.skills_to_avoid &&
      Object.keys(this.props.skills_to_avoid).length !== 0;
    var avoided =
      this.props.skills_avoided &&
      Object.keys(this.props.skills_avoided).length !== 0;

    if (!(avoid || avoided)) {
      return <Redirect to={`/${id}/exit${this.props.location.search}`} />;
    }

    return (
      <div className="flex-container">
        {header}
        <div className="content expand">{this.renderItems()}</div>
        <div className="footer">
          <NavButton
            text="Finish"
            link={`/${id}/exit${this.props.location.search}`}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    skills_to_avoid: state.aar_avoids.failed,
    skills_avoided: state.aar_avoids.passed,
    header_hidden: state.header_hidden,
  };
};

export default connect(mapStateToProps)(AARAvoid);
