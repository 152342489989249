/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { loadData, ensureTaskAndPhrasingDataLoaded } from "actions";
import { setQuery } from "actions/query";
import HeaderBar from "components/HeaderBar";
import NavButton from "components/NavButton";
import MediaCard from "components/MediaCard";

class BackgroundInfo extends Component {
  static propTypes = {
    data: PropTypes.object,
    didLoad: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasReachedBottom: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadData(`/inots_counselingscenarioinfo.xml`));
    dispatch(ensureTaskAndPhrasingDataLoaded());
    setQuery(this.props.location.search, dispatch);
  }

  componentDidUpdate() {
    if (!this.props.data || this.state.hasReachedBottom) {
      return;
    }
    this.updateHasReachedBottom();
  }

  formatString(string) {
    string = string.toLowerCase();
    string = string.replace(/_/g, " ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  paneDidMount = (node) => {
    if (!this.state.hasReachedBottom && node) {
      node.addEventListener("scroll", this.handleScroll.bind(this));
    }
  };

  hasReachedBottom(node) {
    return node.clientHeight >= (node.scrollHeight - node.scrollTop) * 0.95;
  }

  updateHasReachedBottom() {
    if (this.state.hasReachedBottom || !this.props.didLoad) {
      return;
    }
    const node = document.getElementById("scenario-info-content");
    if (!node) {
      console.error(`element for id scenario-info-content is undefined`);
      return;
    }
    if (!this.hasReachedBottom(node)) {
      return;
    }
    this.setState({
      hasReachedBottom: true,
    });
  }

  handleScroll(e) {
    this.updateHasReachedBottom(e.target);
  }

  contentBlockWithTitle(content, title) {
    return <MediaCard title={title} content={content} />;
  }

  renderBackground() {
    const background =
      this.props.didLoad && this.props.data
        ? jsonpath.value(this.props.data, "$..BACKGROUND..DESCRIPTION")
        : undefined;
    return this.contentBlockWithTitle(background, "Background:");
  }

  renderWhoYouAre() {
    const who_you_are =
      this.props.didLoad && this.props.data
        ? jsonpath.value(this.props.data, "$..WHO_YOU_ARE..DESCRIPTION")
        : undefined;
    return this.contentBlockWithTitle(who_you_are, "Who You Are:");
  }

  renderPersonnelInvolved() {
    const personnel =
      this.props.didLoad && this.props.data ? (
        <div>
          {jsonpath.value(this.props.data, "$..PERSON").map((person, pi) => (
            <div key={`p-${pi}`}>
              {Object.keys(jsonpath.value(person, "$..*")).map((key, ppi) => (
                <div key={`pp-${ppi}`}>
                  <Typography variant="body1">
                    <b>{this.formatString(key)}:</b>{" "}
                    {jsonpath.value(person, `$..${key}`)} <br />
                  </Typography>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : undefined;

    return this.contentBlockWithTitle(personnel, "Personnel Involved:");
  }

  render() {
    const { id } = this.props.match.params;
    const header = this.props.header_hidden ? undefined : (
      <HeaderBar text="Scenario Info" key="header-bar" sm={1} />
    );

    if (!this.props.didLoad) {
      return (
        <div className="flex-container">
          {header}
          <div className="expand">
            <CircularProgress className="spinner" />
          </div>
        </div>
      );
    }

    return (
      <div className="flex-container" sm={1}>
        {header}
        <div
          ref={this.paneDidMount}
          sm={1}
          className="content expand"
          id="scenario-info-content"
        >
          <List>
            <ListItem key="item-bkg">{this.renderBackground()}</ListItem>
            <ListItem key="item-who">{this.renderWhoYouAre()}</ListItem>
            <ListItem key="item-personnel">
              {this.renderPersonnelInvolved()}
            </ListItem>
          </List>
        </div>
        <div className="footer">
          <NavButton
            link={`/${id}/scenario${this.props.location.search}`}
            text="Continue"
            isDisabled={!this.state.hasReachedBottom}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    data: state.data
      ? jsonpath.value(state.data, `$..SCENARIO[?(@.$.ID==${id})]`)
      : state.data,
    didLoad: state.didLoad,
    header_hidden: state.header_hidden,
  };
};

export default connect(mapStateToProps)(BackgroundInfo);
