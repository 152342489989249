/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import XMLData from "./XMLData";
import { isObj } from "./DataUtils";

const _xmlToSkills = (xmlData) => {
  const skillsRaw = jsonpath.value(
    xmlData,
    `$..Field[?(@.Title=='Tasks')].Value..*`
  );
  if (typeof skillsRaw === "undefined") {
    return undefined;
  }
  return skillsRaw
    .toString()
    .trim()
    .split(",")
    .reduce((acc, cur) => {
      const skillAndScore = cur.trim().split(" ");
      if (skillAndScore.length !== 2) {
        return acc;
      }
      const skill = skillAndScore[0].trim();
      if (skill.length === 0 || Number(skill.replace(/-/g, "")) === 0) {
        // console.warn(`data contains bug-artifact skill ${skill}`, xmlData)
        return acc;
      }

      acc[skillAndScore[0]] =
        skillAndScore.length > 1
          ? skillAndScore[1].indexOf("+") >= 0
            ? 1
            : 0
          : 0;
      return acc;
    }, {});
};

const _valueOfXmlFieldWithTitle = (xmlData, title) => {
  const value = jsonpath.value(
    xmlData,
    `$..Field[?(@.Title=='${title}')].Value..*`
  );
  return value ? value.toString() : undefined;
};

const _isObj = (data) => data !== null && typeof data === "object";

const _stringValueOfDataField = (data, field, defaultValue = undefined) => {
  if (!_isObj(data)) {
    return defaultValue;
  }
  const v = data[field];
  return v ? v.toString() : defaultValue;
};

class DialogData {
  /**
   * Read the passed in xmlData (converted from a DialogEntry element in xml)
   * and use it to configure a dialogData json object.
   * @param {object} xmlData
   * @param {object} [dialogData] if passed, properties will be assigned to the passed in object. Otherwise a new dialog obj is created and returned
   * @returns {object} the dialog data
   */
  static fromXMLData(xmlData, dialogData) {
    dialogData = typeof dialogData === "object" ? dialogData : {};
    DialogData.setFeedback(
      dialogData,
      jsonpath.value(xmlData, `$.${XMLData.ATTRS}.NodeColor`)
    );
    DialogData.setLinks(
      dialogData,
      XMLData.toStringArray(xmlData, `$..DestinationDialogID`)
    );
    DialogData.setSkills(dialogData, _xmlToSkills(xmlData));
    DialogData.setText(
      dialogData,
      _valueOfXmlFieldWithTitle(xmlData, "Dialogue Text")
    );
    DialogData.setDecision(
      dialogData,
      _valueOfXmlFieldWithTitle(xmlData, "Decision ID")
    );
    return dialogData;
  }

  /**
   * Feedback to display to user for having chosen the option that led to this dialog node.
   * One of
   *   - white: no feedback
   *   - green: positive
   *   - yellow: mixed
   *   - pink: negative
   */
  static getFeedback(data) {
    const v = _stringValueOfDataField(data, "feedback");
    return v ? v.toLowerCase() : undefined;
  }
  static setFeedback(data, value) {
    data["feedback"] =
      typeof value === "string" ? value.toLowerCase() : undefined;
  }

  /**
   * NOTE: you almost always want to use getNextDecisionLinks
   * Array of (string) ids for options the player may choose from this node.
   * The ids are refs to other Dialog nodes.
   */
  static getLinks(data) {
    return _isObj(data) && Array.isArray(data["links"]) ? data["links"] : [];
  }
  static setLinks(data, value) {
    data["links"] = Array.isArray(value) ? value : undefined;
  }

  /**
   * Array of (string) ids for options the player may choose from this node.
   * The ids are refs to other Dialog nodes.
   * NOTE: sometimes needs to traverse a few nodes to find one where player can make a decision
   */
  static getNextDecisionLinks(data, scenariosById) {
    if (typeof data === "string") {
      if (!isObj(scenariosById[data])) {
        console.warn(`no dialog node found for ${data}`);
        return [];
      }
      data = scenariosById[data];
    }
    if (!isObj(data)) {
      console.warn(`dialog node is undefined or null`);
      return [];
    }
    // TODO: make a test for this method and also change app to use it everywhere (e.g. scenario player)
    const links = DialogData.getLinks(data);
    if (!Array.isArray(links)) {
      return [];
    }
    if (links.length !== 1) {
      return links;
    }
    // There's only one link out from this dialog node...
    // We look up the data for that node.
    // If it has skills associated with it
    // we'll consider the (single) link to it a decision
    const nextD = scenariosById[links[0]];
    const skillIds = DialogData.getSkillIds(nextD);
    if (Array.isArray(skillIds) && skillIds.length > 0) {
      return links;
    }
    // Final check...
    // If the next node has as a decision (a video to play), we skip it
    // This seems backwards but it's just how the crazy XML data is defined
    const decision = DialogData.getDecision(nextD);
    return typeof decision === "string" && decision.trim().length > 0
      ? DialogData.getNextDecisionLinks(nextD, scenariosById)
      : links;
  }

  /**
   * The text spoken in this dialog node (or undefined if none)
   */
  static getText(data) {
    return _stringValueOfDataField(data, "text");
  }
  static setText(data, value) {
    data["text"] = typeof value !== "undefined" ? value.toString() : undefined;
  }

  /**
   * id of this decision (weird that we don't just use Dialog ID for this)
   */
  static getDecision(data) {
    return _stringValueOfDataField(data, "decision");
  }
  static setDecision(data, value) {
    data["decision"] =
      typeof value !== "undefined" ? value.toString() : undefined;
  }

  /**
   * id of idle to play while player makes their next choice
   */
  static getIdle(data) {
    switch (DialogData.getFeedback(data)) {
      case "pink":
        return "idle_back";
      case "yellow":
        return "idle_forward";
      case "green":
        return "idle_upright";
      default:
        return null;
    }
  }

  /**
   * Array of (string) ids listed as skills for the given dialog node
   */
  static getSkillIds(data) {
    if (!isObj(data)) {
      return [];
    }
    return Object.getOwnPropertyNames(DialogData.getSkills(data));
  }

  /**
   * Get the score for a skill id
   * @param {object} data
   * @param {string} skillId
   * @param {float} [defaultScore]
   */
  static getSkillScore(data, skillId, defaultScore = 0) {
    if (!_isObj(data)) {
      return defaultScore;
    }
    const score = DialogData.getSkills(data)[skillId];
    return !isNaN(Number(score)) ? score : defaultScore;
  }

  static getSkills(data) {
    return _isObj(data) && _isObj(data["skills"]) ? data["skills"] : {};
  }
  static setSkills(data, value) {
    data["skills"] =
      isObj(value) && Object.getOwnPropertyNames(value).length > 0
        ? value
        : undefined;
  }
}
export default DialogData;
