/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import XMLData from "./XMLData";

/**
 * Access to data for phrasing skills as hints or feedback, e.g.
 *   - Hint: You should <hint phrasing for skill player should apply to next choice>
 *   - Feedback: You did not <feedback phrasing for skill player failed to apply>
 *   - Warning - It is wrong to <feedback phrasing for anti-skill player should AVOID>. Avoid doing this.
 */
class SkillPhrasingData {
  static get DEFAULT_MSG_FORMAT_FEEDBACK_SKILL_MISSED() {
    return "You did not {0}";
  }
  static get DEFAULT_MSG_FORMAT_FEEDBACK_AVOID() {
    return "Warning - It is wrong to {0} Avoid doing this.";
  }
  static get DEFAULT_MSG_FORMAT_HINT() {
    return "You should {0}";
  }

  static formatMsg(m, f) {
    return f.replace("{0}", m);
  }

  /**
   * Convert skill_phrasing xml to a normalized form of (Map<string,string>) phrasing_by_skill_id
   */

  static async fromXML(xml) {
    // xml has root element with '.'s in element name, which causes havok in jsonpath
    // so we just replace it with 'root'
    const data = await XMLData.toJson(
      xml
        .toString()
        .replace(
          /edu.usc.cct.learn.ped.representation.FacilitatingPropertySets/g,
          "root"
        )
    );
    const items = jsonpath.query(data, `$..FacilitatingPropertySet[*]`);
    return items.reduce((acc, cur) => {
      const skillId = jsonpath.value(cur, `$.${XMLData.ATTRS}.taskUUID`);
      // the xml design allows that there could be multiple hint phrasings
      // for each skill, so we just take the first one
      const phrasing = jsonpath.value(cur, `$..hints[0].Hint[0]`);
      if (
        typeof skillId === "string" &&
        typeof phrasing === "string" &&
        skillId &&
        phrasing
      ) {
        acc[skillId] = phrasing;
      }
      return acc;
    }, {});
  }
}
export default SkillPhrasingData;
