/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import { parseString as parseXml } from "xml2js";

const { promisify } = require("es6-promisify");
const parseXmlAsync = promisify(parseXml);

class XMLData {
  /**
   * JSON property name for the object that will hold each (converted) xml element's attributes.
   * xml2js module names this property '$', but '__attr__' is much easier to work with.
   */
  static get ATTRS() {
    return "__attrs__";
  }

  /**
   * Convert XML data to JSON.
   * Uses xml2js lib, but with one variation:
   * xml2js outputs each element's attributes in a parent element with the name '$'.
   * That can be a pain to work with, particularly because the jsonpath module
   * has a special meaning for $, so here instead we convert all '$' elements to '__attr__'
   */
  static async toJson(xml) {
    return JSON.parse(
      JSON.stringify(await parseXmlAsync(xml)).replace(/\$/g, XMLData.ATTRS)
    );
  }

  static toStringArray(xmlData, jspath) {
    const items = jsonpath.query(xmlData, jspath);
    return items.reduce((acc, cur) => {
      if (cur) {
        acc.push(cur.toString());
      }
      return acc;
    }, []);
  }
}
export default XMLData;
