/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import axios from "axios";
import { parseString as parseXml } from "xml2js";

import { isObj } from "data/DataUtils";
import ScenarioData from "data/ScenarioData";
import Scoring from "data/Scoring";
import TaskData from "data/TaskData";
import SkillPhrasingData from "data/SkillPhrasingData";
import { cmi5_instance } from "./query";

const { promisify } = require("es6-promisify");
const parseXmlAsync = promisify(parseXml);

export const REQUEST_DATA = "REQUEST_DATA";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const SCENARIO_DATA_LOAD_REQUESTED = "SCENARIO_DATA_LOAD_REQUESTED";
export const SCENARIO_DATA_LOAD_FAILED = "SCENARIO_DATA_LOAD_FAILED";
export const SCENARIO_DATA_LOAD_SUCCEEDED = "SCENARIO_DATA_LOAD_SUCCEEDED";
export const ADD_DIALOGUE = "ADD_DIALOGUE";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const SKILL_PHRASING_DATA_LOAD_FAILED =
  "SKILL_PHRASING_DATA_LOAD_FAILED";
export const SKILL_PHRASING_DATA_LOAD_REQUESTED =
  "SKILL_PHRASING_DATA_LOAD_REQUESTED";
export const SKILL_PHRASING_DATA_LOAD_SUCCEEDED =
  "SKILL_PHRASING_DATA_LOAD_SUCCEEDED";
export const TASK_DATA_LOAD_FAILED = "TASK_DATA_LOAD_FAILED";
export const TASK_DATA_LOAD_REQUESTED = "TASK_DATA_LOAD_REQUESTED";
export const TASK_DATA_LOAD_SUCCEEDED = "TASK_DATA_LOAD_SUCCEEDED";
export const SET_HEADER_HIDDEN = "SET_HEADER_HIDDEN";

const CMI5_EXT_RESULT_KC_SCORES =
  "http://pal3.org/xapi/knowledgecomponent/result/kc-scores";

export const submitResultsAndTerminate = () => (dispatch, getState) => {
  const state = getState();
  if (!isObj(state.score_data)) {
    console.warn(`submit cmi5 result finds not scores stored in state!`);
  }
  const scoreData = isObj(state.score_data)
    ? state.score_data
    : { score_normalized: 0 };
  const kcScores = state.kc_scores;
  if (!Array.isArray(kcScores)) {
    console.warn(`submit cmi5 result has no kc_scores in state`);
  }
  if (isNaN(Number(scoreData.score_normalized))) {
    console.error("no score in score_data: ", scoreData);
  }
  const score = !isNaN(scoreData.score_normalized)
    ? scoreData.score_normalized
    : 0;
  const failed = score < Scoring.MASTERY_THRESHOLD;
  const extensions = {
    [CMI5_EXT_RESULT_KC_SCORES]: Array.isArray(kcScores) ? kcScores : [],
  };
  if (cmi5_instance) {
    if (failed) {
      cmi5_instance.fail(score, {
        transform: (s) => {
          return {
            ...s,
            result: {
              score: {
                scaled: score,
              },
              extensions: extensions,
            },
          };
        },
      });
    } else {
      cmi5_instance.pass(score, {
        transform: (s) => {
          return {
            ...s,
            result: {
              score: {
                scaled: score,
              },
              extensions: extensions,
            },
          };
        },
      });
    }
    cmi5_instance.terminate();
  }
};

export const ensureTaskAndPhrasingDataLoaded = () => (dispatch, getState) => {
  const state = getState();
  if (!(state.tasks_loaded || state.tasks_load_in_progress)) {
    dispatch(loadTaskData("/icare_lisa.xml"));
  }
  if (
    !(state.skill_phrasings_loaded || state.skill_phrasings_load_in_progress)
  ) {
    dispatch(loadSkillPhrasingData("/elite_inots.xml"));
  }
};

export const loadTaskData = (url) => (dispatch) => {
  dispatch({ type: TASK_DATA_LOAD_REQUESTED });
  return axios.get(url).then(function (response) {
    const xml = response.data;
    TaskData.fromXML(xml)
      .then((data) => {
        dispatch({ type: TASK_DATA_LOAD_SUCCEEDED, data });
      })
      .catch((err) => {
        console.error(`error parsing task-data result from url ${url}`, err);
        dispatch({ type: TASK_DATA_LOAD_FAILED, error: err.message });
      });
  });
};
export const loadSkillPhrasingData = (url) => (dispatch) => {
  dispatch({ type: SKILL_PHRASING_DATA_LOAD_REQUESTED });
  return axios.get(url).then(function (response) {
    const xml = response.data;
    SkillPhrasingData.fromXML(xml)
      .then((data) => {
        dispatch({ type: SKILL_PHRASING_DATA_LOAD_SUCCEEDED, data });
      })
      .catch((err) => {
        console.error(
          `error parsing phrasing-data result from url ${url}`,
          err
        );
        dispatch({ type: SKILL_PHRASING_DATA_LOAD_FAILED, error: err.message });
      });
  });
};
export const loadData = (url) => (dispatch, getState) => {
  console.error(
    `Deprecated: this cannot be like this: a single action that loads all data`
  );
  if (shouldFetchData(getState(), url)) {
    return dispatch(fetchData(url));
  }
};

export const requestData = (url) => ({
  type: REQUEST_DATA,
  url,
});

export const receiveData = (url, data) => (dispatch, getState) => {
  dispatch({
    type: RECEIVE_DATA,
    url,
    data,
  });
  return Promise.resolve(getState());
};

export const logDialogue = (id) => ({
  type: ADD_DIALOGUE,
  id,
});

export const logFeedback = (i) => (dispatch) => {
  const id = i.replace(/ \((.*?)\)/g, "");
  const feedback = i.replace(/.*?(\([^)]*\)).*?/g, "$1");

  dispatch({
    type: ADD_FEEDBACK,
    id: id,
    feedback: feedback,
  });
};

export const setHeaderHidden = (hidden) => ({
  type: SET_HEADER_HIDDEN,
  hidden,
});

const shouldFetchData = (state, url) => {
  if (!state.data) {
    return true;
  }
  if (!state.data_url) {
    return true;
  }
  if (state.data_url !== url) {
    return true;
  }
  return false;
};

const fetchData = (url) => (dispatch) => {
  dispatch(requestData(url));
  return axios.get(url).then(function (response) {
    const xml = response.data;
    Promise.all([parseXmlAsync(xml), ScenarioData.fromXML(xml)])
      .then((result) => {
        dispatch({
          type: SCENARIO_DATA_LOAD_SUCCEEDED,
          result: {
            data: result[0],
            ...result[1],
          },
        });
      })
      .catch((err) => {
        console.error(`error parsing result from url ${url}`, err);
        dispatch({ type: SCENARIO_DATA_LOAD_FAILED, error: err.message });
      });
  });
};
