/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import React, { Component } from "react";
import FlipMove from "react-flip-move";

import { Typography, List, ListItem } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import AnswerButton from "components/AnswerButton";

/**
 * @param props.width: width of subtitle/option area
 * @param props.isIdle: is video idle
 * @param props.subtitle: full subtitle text
 *
 * @param props.options: list of answer options for current video
 * @param props.answer: currently selected option
 * @param props.onOptionSelected: callback when option is selected
 */
class SubtitleOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTruncated: false,
      isExpanded: true,

      offset: 0,
      height: 0,
      fullHeight: 0,
      collapsedHeight: 0,
    };
  }

  componentDidUpdate(prevProps) {
    // video is transitioning between idle and playing
    if (this.props.isIdle !== prevProps.isIdle) {
      this.setState({
        isExpanded: !this.props.isIdle,
      });
    }
    this.updateSubHeight();
  }

  updateSubHeight() {
    const fullSubs = document.getElementById("subtitle-full");
    if (fullSubs && fullSubs.clientHeight !== this.state.fullHeight) {
      this.setState({
        fullHeight: fullSubs.clientHeight,
      });
    }

    const truncSubs = document.getElementById("subtitle-truncated");
    if (truncSubs && truncSubs.clientHeight !== this.state.collapsedHeight) {
      this.setState({
        collapsedHeight: truncSubs.clientHeight,
      });
    }

    const subs = document.getElementById("subtitle");
    if (subs && subs.clientHeight !== this.state.height) {
      this.setState({
        height: subs.clientHeight,
      });
    }

    if (fullSubs && truncSubs) {
      const isTruncated = fullSubs.clientHeight !== truncSubs.clientHeight;
      if (isTruncated !== this.state.isTruncated) {
        this.setState({
          isTruncated: isTruncated,
        });
      }
    }

    const offset = this.props.isIdle
      ? this.state.isExpanded
        ? -this.state.fullHeight
        : -this.state.collapsedHeight
      : 0;
    if (offset !== this.state.offset) {
      this.setState({
        offset: offset,
      });
    }
  }

  onToggleSubtitles = () => {
    if (!this.props.isIdle) {
      return;
    }
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  renderSubtitles() {
    const canExpand = this.props.isIdle && this.state.isTruncated;
    const isTruncated = canExpand && !this.state.isExpanded;

    return (
      <div style={{ width: this.props.width }}>
        <div
          className="subtitle-container"
          onClick={this.onToggleSubtitles}
          style={{ top: this.state.offset }}
        >
          <div
            id="subtitle"
            style={{
              height: isTruncated
                ? this.state.collapsedHeight
                : this.state.fullHeight,
            }}
            className={`subtitle ${this.props.isIdle ? "idle" : "playing"}`}
          >
            <Typography variant="body1" color="inherit" noWrap={isTruncated}>
              {this.props.subtitle}
            </Typography>
          </div>

          <div
            className={`arrow ${canExpand ? "showing" : "hidden"}`}
            style={{ transform: this.state.isExpanded ? "rotate(180deg)" : "" }}
          >
            <ArrowDropUpIcon />
          </div>
        </div>

        <div
          id="subtitle-full"
          style={{ position: "absolute", width: this.props.width }}
          className="subtitle invisible"
        >
          <Typography variant="body1" color="inherit">
            {this.props.subtitle}
          </Typography>
        </div>

        <div
          id="subtitle-truncated"
          style={{ position: "absolute", width: this.props.width }}
          className="subtitle invisible"
        >
          <Typography variant="body1" color="inherit" noWrap={true}>
            {this.props.subtitle}
          </Typography>
        </div>
      </div>
    );
  }

  renderOptions() {
    return (
      <div
        className="options"
        style={{ width: this.props.width, top: this.state.offset }}
      >
        <List>
          <FlipMove>
            {this.props.options.map((option, index) =>
              this.renderOption(
                index,
                option["ID"],
                option["feedback"],
                option["text"]
              )
            )}
          </FlipMove>
        </List>
      </div>
    );
  }

  renderOption(index, id, feedback, text) {
    const isDisabled = !this.props.isIdle;
    const isSelected = this.props.answer === id;

    return (
      <ListItem key={id}>
        <AnswerButton
          text={text}
          isDisabled={isDisabled}
          isSelected={isSelected}
          onClick={() => this.props.onOptionSelected(index, id, feedback)}
        />
      </ListItem>
    );
  }

  render() {
    return (
      <div style={{ width: this.props.width }}>
        {this.renderSubtitles()}
        {this.renderOptions()}
      </div>
    );
  }
}

export default SubtitleOptions;
