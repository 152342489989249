/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import { isObj, stringValueOfDataField } from "./DataUtils";
import DialogData from "./DialogData";
import ScenarioData from "./ScenarioData";
import TaskData from "./TaskData";
import SkillPhrasingData from "./SkillPhrasingData";

/**
 * convert an string array into an object with propname=propname for every value in the array
 * @param {array} a
 * @returns {object}
 */
const _aToO = (a) => {
  if (!Array.isArray(a)) {
    return {};
  }
  return a.reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
  }, {});
};

/**
 * get the unique union an array of arrays of strings
 * @param {Array<Array<string>>} arrays
 * @returns {Array<string>} of strings that are unique union all input arrays
 */
const _uniqueUnion = (arrays) => {
  return arrays.reduce((acc, cur, i, arr) => {
    if (i === 0) {
      return cur;
    }
    const xO = _aToO(acc);
    const yO = _aToO(cur);
    return Object.getOwnPropertyNames({ ...xO, ...yO });
  }, []);
};

const _aggrTaskScoreFromSkillScores = (skillScores, skillsById) => {
  if (!isObj(skillScores)) {
    return [];
  }
  return Object.getOwnPropertyNames(skillScores).reduce((acc, cur, i) => {
    const curSkill = skillsById[cur];
    if (!isObj(curSkill)) {
      console.warn(
        `scores include skill '${cur}', which is not found in lookup`
      );
      return acc;
    }
    const curSkillScore = Number(skillScores[cur].score);
    const curSkillScoreMax = Number(skillScores[cur].score_max);
    if (
      isNaN(curSkillScore) ||
      isNaN(curSkillScoreMax) ||
      curSkillScoreMax <= 0
    ) {
      console.warn(`scores include invalid entry for ${cur}`, skillScores[cur]);
      return acc;
    }
    if (acc[curSkill.task]) {
      acc[curSkill.task].score += curSkillScore;
      acc[curSkill.task].score_max += curSkillScoreMax;
      acc[curSkill.task].score_normalized =
        acc[curSkill.task].score / acc[curSkill.task].score_max;
    } else {
      acc[curSkill.task] = {
        score: curSkillScore,
        score_max: curSkillScoreMax,
        score_normalized: curSkillScore / curSkillScoreMax,
      };
    }
    return acc;
  }, {});
};

const _sortArrayProps = (o) => {
  if (!isObj(o)) {
    return;
  }
  Object.getOwnPropertyNames(o).forEach((n) => {
    if (Array.isArray(o[n])) {
      o[n].sort();
    }
  });
};

const _idsToNames = (ids, objById, nameProp = "name", sort = true) => {
  const a = ids.reduce((acc, cur) => {
    const name = stringValueOfDataField(objById[cur], nameProp);
    if (name) {
      acc.push(name);
    }
    return acc;
  }, []);
  if (sort) {
    a.sort();
  }
  return a;
};

const KC_NAME_FORMAT_DEFAULT = "inots-{0}-application";

class Scoring {
  /**
   * The normalized score player needs to pass/master a skill or the scenario as a whole.
   * Hard coded for now, but can move to config as needed
   */
  static get MASTERY_THRESHOLD() {
    return 0.85;
  }

  /**
   * Get scoring info for a single player choice
   * where the player started on a dialog node and chose one of its options
   *
   * @param {string} idChosen the dialog option selected by player
   * @param {string} idFrom the dialog node from which the player made their selection (provides the set of options from which they picked)
   * @param {object} dialogsById lookup table of dialog data by id
   * @param {object} tasksById lookup table for tasks (so we can know the name of each task; we use task.name for kc)
   * @returns {object} scoring info with breakdown by skills
   */
  static scoreChoice(idChosen, idFrom, dialogsById, tasksById) {
    const dChosen = dialogsById[idChosen];
    if (!isObj(dChosen)) {
      throw new Error(`no dialog for id ${idChosen}`);
    }
    const dFrom = dialogsById[idFrom];
    if (!isObj(dFrom)) {
      throw new Error(`no dialog for id ${idFrom}`);
    }
    // Go back to the decision node the player chose FROM.
    // If the player did NOT choose the correct choice,
    // then make sure they are scored on the skills
    // that they SHOULD HAVE demonstrated
    // (the positive skills associated with the correct choice)
    const validOptions = DialogData.getNextDecisionLinks(dFrom, dialogsById);
    if (!Array.isArray(validOptions) || !validOptions.includes(idChosen)) {
      return {
        score: 0,
        score_max: 0,
        score_normalized: 0,
        scores_by_skill: {},
      };
    }
    const idCorrect = ScenarioData.findCorrectChoice(idFrom, dialogsById);
    const dCorrect = idCorrect ? dialogsById[idCorrect] : null;
    // Go back (again) to the decision node the player chose FROM
    // Look at all of the options the player DID NOT choose.
    // If any of those options has a penalty for one of the magic AVOID
    // skill ids, then give the player a positive score for having
    // avoided that bad choice.
    const penaltyIdsAvoided = ScenarioData.findSkillPenaltiesAvoidedByChoice(
      idChosen,
      idFrom,
      dialogsById,
      TaskData.findAvoidSkillIds(tasksById)
    );
    const skillIdsScored = _uniqueUnion([
      DialogData.getSkillIds(dCorrect),
      DialogData.getSkillIds(dChosen),
      penaltyIdsAvoided,
    ]);
    return skillIdsScored.reduce(
      (acc, cur) => {
        const skillScore = DialogData.getSkillScore(
          dChosen,
          cur,
          // if the cur skill is one of the penaltyIdsAvoided
          // AND there's no other score,
          // the the default score for this skill id is 1 instead of 0
          penaltyIdsAvoided.includes(cur) ? 1.0 : 0.0
        );
        acc.score += skillScore;
        acc.score_normalized += skillScore / skillIdsScored.length;
        acc.scores_by_skill[cur] = skillScore;
        return acc;
      },
      {
        score: 0,
        score_max: skillIdsScored.length,
        score_normalized: 0,
        scores_by_skill: {},
      }
    );
  }

  /**
   * Update hints and feedback for a dialog node that is newly active.
   * This is implemented as an update (as opposed to a simple get)
   * because each answer implies one of three update types as follows:
   *   - new feedback+hints (when the dialog node is a player choice and a SUBOPTIMAL answer)
   *   - clear feedback+hints (when the dialog node is a player choice and the CORRECT answer)
   *   - keep feedback+hints (when the dialog node is not a player choice)
   * @param {feedback_by_skill_id,hints_by_skill_id} curHintsAndFeedback the current hints and feedback (before the new diag is applied)
   * @param {string} diagId the new diag node. May be a player choice or an intersitial
   * @param {string:Dialogdata} dialogsById map of dialog node datas by id
   * @param {string:SkillPhrasingData} phrasingBySkillId map of skill-phrasing datas by id
   * @param {string[]} antiSkillIds list of skill ids that are anti skills--things the player must avoid doing
   * @param {string} opts.msg_format_feedback_skill_missed override how skill feedback is phrased to player
   * @param {string} opts.msg_format_feedback_avoid override how feedback about antiskills is phrased to player
   * @param {string} opts.msg_format_hint override how hints are phrased to player
   */
  static updateHintsAndFeedback = (
    curHintsAndFeedback,
    diagId,
    dialogsById,
    phrasingBySkillId,
    antiSkillIds,
    opts
  ) => {
    if (ScenarioData.isPassThroughNode(diagId, dialogsById)) {
      return curHintsAndFeedback;
    }
    opts = opts || {};
    const diag = dialogsById[diagId];
    if (!isObj(diag)) {
      console.warn(`no dialog for id ${diagId}`);
      return curHintsAndFeedback;
    }
    const feedbackSkillIds = DialogData.getSkillIds(diag) || [];
    if (
      !feedbackSkillIds.some(
        (x) => DialogData.getSkillScore(diag, x, 1.0) < 1.0
      )
    ) {
      // no penalties, so clear the feedback
      return { feedback_by_skill_id: {}, hints_by_skill_id: {} };
    }
    const msgSkillMissed =
      opts.msg_format_feedback_skill_missed ||
      SkillPhrasingData.DEFAULT_MSG_FORMAT_FEEDBACK_SKILL_MISSED;
    const msgAvoid =
      opts.msg_format_feedback_avoid ||
      SkillPhrasingData.DEFAULT_MSG_FORMAT_FEEDBACK_AVOID;
    // use '_' naming to match result naming
    const feedback_by_skill_id = feedbackSkillIds.reduce((acc, cur) => {
      if (DialogData.getSkillScore(diag, cur, 1.0) >= 1.0) {
        return acc;
      }
      const phrasing = phrasingBySkillId[cur];
      if (typeof phrasing !== "string" || !phrasing) {
        return acc;
      }
      const isAntiSkill = antiSkillIds.includes(cur);
      const isNegative = DialogData.getFeedback(diag) === "pink";
      acc[cur] = {
        skill_phrasing: phrasing,
        message: isAntiSkill
          ? SkillPhrasingData.formatMsg(phrasing, msgAvoid)
          : SkillPhrasingData.formatMsg(phrasing, msgSkillMissed),
        is_anti_skill: isAntiSkill,
        is_negative: isNegative,
      };
      return acc;
    }, {});
    const correctId = ScenarioData.findCorrectChoice(diagId, dialogsById);
    const correctDiag = correctId ? dialogsById[correctId] : null;
    const correctSkillIds = DialogData.getSkillIds(correctDiag) || [];
    const msgHint =
      opts.msg_format_hint || SkillPhrasingData.DEFAULT_MSG_FORMAT_HINT;
    const hints_by_skill_id = correctSkillIds.reduce((acc, cur) => {
      const phrasing = phrasingBySkillId[cur];
      if (typeof phrasing !== "string" || !phrasing) {
        return acc;
      }
      acc[cur] = {
        skill_phrasing: phrasing,
        message: SkillPhrasingData.formatMsg(phrasing, msgHint),
      };
      return acc;
    }, {});
    return { feedback_by_skill_id, hints_by_skill_id };
  };

  static getHintsAndFeedback = (
    diagId,
    dialogsById,
    phrasingBySkillId,
    antiSkillIds,
    opts
  ) => {
    opts = opts || {};
    const diag = dialogsById[diagId];
    if (!isObj(diag)) {
      throw new Error(`no dialog for id ${diagId}`);
    }
    const feedbackSkillIds = DialogData.getSkillIds(diag) || [];
    const msgSkillMissed =
      opts.msg_format_feedback_skill_missed ||
      SkillPhrasingData.DEFAULT_MSG_FORMAT_FEEDBACK_SKILL_MISSED;
    const msgAvoid =
      opts.msg_format_feedback_avoid ||
      SkillPhrasingData.DEFAULT_MSG_FORMAT_FEEDBACK_AVOID;
    // use '_' naming to match result naming
    const feedback_by_skill_id = feedbackSkillIds.reduce((acc, cur) => {
      if (DialogData.getSkillScore(diag, cur, 1.0) >= 1.0) {
        return acc;
      }
      const phrasing = phrasingBySkillId[cur];
      if (typeof phrasing !== "string" || !phrasing) {
        return acc;
      }
      const isAntiSkill = antiSkillIds.includes(cur);
      acc[cur] = {
        skill_phrasing: phrasing,
        message: isAntiSkill
          ? SkillPhrasingData.formatMsg(phrasing, msgAvoid)
          : SkillPhrasingData.formatMsg(phrasing, msgSkillMissed),
        is_anti_skill: isAntiSkill,
      };
      return acc;
    }, {});
    const correctId = ScenarioData.findCorrectChoice(diagId, dialogsById);
    const correctDiag = correctId ? dialogsById[correctId] : null;
    const correctSkillIds = DialogData.getSkillIds(correctDiag) || [];
    const msgHint =
      opts.msg_format_hint || SkillPhrasingData.DEFAULT_MSG_FORMAT_HINT;
    const hints_by_skill_id = correctSkillIds.reduce((acc, cur) => {
      const phrasing = phrasingBySkillId[cur];
      if (typeof phrasing !== "string" || !phrasing) {
        return acc;
      }
      acc[cur] = {
        skill_phrasing: phrasing,
        message: SkillPhrasingData.formatMsg(phrasing, msgHint),
      };
      return acc;
    }, {});
    return { feedback_by_skill_id, hints_by_skill_id };
  };

  /**
   * Get the accumulated scoring info for a path of player choices
   *
   * @param {array} idsChosen the path of id choices made by the player
   * @param {object} dialogsById lookup table of dialog data by id
   * @param {object} tasksById lookup table for tasks (so we can know the name of each task; we use task.name for kc)
   * @returns {object} scoring info with breakdown by skills
   */
  static scoreChoicePath(idsChosen, dialogsById, tasksById) {
    return idsChosen.reduce(
      (acc, cur, i) => {
        if (i === 0) {
          return acc; // first choice isn't scored
        }
        try {
          const curScore = Scoring.scoreChoice(
            cur,
            idsChosen[i - 1],
            dialogsById,
            tasksById
          );
          acc.score += curScore.score;
          acc.score_max += curScore.score_max;
          acc.score_normalized =
            acc.score_max > 0 ? acc.score / acc.score_max : 0;
          Object.getOwnPropertyNames(curScore.scores_by_skill).forEach((s) => {
            const curSkillScore = !isNaN(Number(curScore.scores_by_skill[s]))
              ? Number(curScore.scores_by_skill[s])
              : 0;
            if (!isObj(acc.scores_by_skill[s])) {
              acc.scores_by_skill[s] = {
                score: curSkillScore,
                score_max: 1,
                score_normalized: curSkillScore,
              };
            } else {
              acc.scores_by_skill[s].score += curSkillScore;
              acc.scores_by_skill[s].score_max += 1;
              acc.scores_by_skill[s].score_normalized =
                acc.scores_by_skill[s].score / acc.scores_by_skill[s].score_max;
            }
          });
          return acc;
        } catch (err) {
          console.error(`error processing score`, err);
          return acc;
        }
      },
      { score: 0, score_max: 0, score_normalized: 0, scores_by_skill: {} }
    );
  }

  /**
   * Convert a dict of skill scores (as output from scoreChoicePath().scores_by_skill)
   * to an array of kc scores
   * @param {object} skillScores skill scores as output from scoreChoicePath().scores_by_skill
   * @param {object} skillsById lookup table for skills (so we can know what task each skill belongs to)
   * @param {object} tasksById lookup table for tasks (so we can know the name of each task; we use task.name for kc)
   * @param {object} [opts.disable_lowercase_kc_names]
   *      Set true to disable default behavior of lowercasing task names in kc-name formatting.
   *      Default is `false`
   * @param {object} [opts.kc_name_format]
   *      Format for turning a task name into a kc name, e.g. 'inots-{0}-application'
   *      Default is `inots-{0}-application` (but that should change
   *      if we use this for something more general purpose than INOTS)
   */
  static kcScoresFromSkillScores(skillScores, skillsById, tasksById, opts) {
    opts = isObj(opts) ? opts : {};
    if (!isObj(skillScores)) {
      console.warn(
        `kcScoresFromSkillScores: skillScores is not a valid dict/object`
      );
      return [];
    }
    const taskScores = _aggrTaskScoreFromSkillScores(skillScores, skillsById);
    return Object.getOwnPropertyNames(taskScores)
      .sort()
      .reduce((acc, cur) => {
        const taskData = tasksById[cur];
        if (!isObj(taskData)) {
          console.warn(`no task for id ${cur}`);
          return acc;
        }
        const taskName = stringValueOfDataField(taskData, "name");
        if (!typeof taskName === "string") {
          console.warn(`unable to find name for task with id ${cur}`);
          return acc;
        }
        const kcNameFormat = stringValueOfDataField(
          opts,
          "kc_name_format",
          KC_NAME_FORMAT_DEFAULT
        );
        let kc =
          typeof kcNameFormat === "string"
            ? kcNameFormat.replace("{0}", taskName)
            : taskName;
        if (!opts.disable_lowercase_kc_names) {
          kc = kc.toLowerCase();
        }
        acc.push({ kc: kc, score: taskScores[cur].score_normalized });
        return acc;
      }, []);
  }

  /**
   * Given a dict of skill scores and lookup tables for skills by id and tasks by id,
   * generates a breakdown for after action review.
   * NOTE: currently generating name-based data structures,
   * down the road would probably be better to have everything here use just ids.
   *
   * @param {object} skillScores skill scores as output from scoreChoicePath().scores_by_skill
   * @param {object} skillsById lookup table for skills (so we can know what task each skill belongs to)
   * @param {object} tasksById lookup table for tasks (so we can know the name of each task; we use task.name for kc)
   * @param {object} [opts.task_filter]
   *      Include only skills whose associated task returns true for this filter.
   *      Should be a function `(taskData) => [true|false]`
   *      Default includes all tasks NOT named 'Avoid', e.g. `t => !/avoid/i.test(t.name)`
   */
  static aarSkillsBreakdownFromSkillScores = (
    skillScores,
    skillsById,
    tasksById,
    opts
  ) => {
    opts = isObj(opts) ? opts : {};
    skillScores = isObj(skillScores) ? skillScores : {};
    const emptyResult = { passed_by_task_name: {}, failed_by_task_name: {} };
    const skills = Object.getOwnPropertyNames(skillScores);
    const taskFilter =
      typeof opts.task_filter === "function"
        ? opts.task_filter
        : (t) => isObj(t) && !/avoid/i.test(t.name);
    const aarBreakdowns = skills.reduce((acc, cur) => {
      const skillData = skillsById[cur];
      if (!isObj(skillData)) {
        console.warn(`no skill for id ${cur}`);
        return acc;
      }
      const skillName = stringValueOfDataField(skillData, "name");
      if (!typeof skillName === "string") {
        console.warn(`unable to find name for skill with id ${cur}`);
        return acc;
      }
      const taskId = stringValueOfDataField(skillData, "task");
      if (!typeof taskId === "string") {
        console.warn(`unable to find task for skill with id ${cur}`);
        return acc;
      }
      const taskData = tasksById[taskId];
      if (!isObj(taskData)) {
        console.warn(`no task for id ${taskId}`);
        return acc;
      }
      if (!taskFilter(taskData)) {
        return acc;
      }
      const taskName = stringValueOfDataField(taskData, "name");
      const by_task_name =
        skillScores[cur].score_normalized >= Scoring.MASTERY_THRESHOLD
          ? acc.passed_by_task_name
          : acc.failed_by_task_name;
      by_task_name[taskName] = Array.isArray(by_task_name[taskName])
        ? by_task_name[taskName]
        : [];
      if (by_task_name[taskName].indexOf(skillName) !== -1) {
        return acc;
      }
      by_task_name[taskName].push(skillName);
      return acc;
    }, emptyResult);
    _sortArrayProps(aarBreakdowns.passed_by_task_name);
    _sortArrayProps(aarBreakdowns.failed_by_task_name);
    return aarBreakdowns;
  };

  /**
   * Given a dict of skill scores and lookup tables for skills by id and tasks by id,
   * generates a breakdown of passed and failed for 'avoid' tasks.
   * Avoid tasks are a subset of tasks filtered from the full set,
   * the default filter being tasks with the name 'Avoid'
   *
   * NOTE: currently generating name-based data structures,
   * down the road would probably be better to have everything here use just ids.
   *
   * @param {object} skillScores skill scores as output from scoreChoicePath().scores_by_skill
   * @param {object} skillsById lookup table for skills (so we can know what task each skill belongs to)
   * @param {object} tasksById lookup table for tasks (so we can know the name of each task; we use task.name for kc)
   * @param {object} [opts.task_filter]
   *      Include only skills whose associated task returns true for this filter.
   *      Should be a function `(taskData) => [true|false]`
   *      Default includes ONLY tasks named 'Avoid', e.g. `t => /avoid/i.test(t.name)`
   */
  static aarAvoidsBreakdownFromSkillScores = (
    skillScores,
    skillsById,
    tasksById,
    opts
  ) => {
    opts = isObj(opts) ? opts : {};
    skillScores = isObj(skillScores) ? skillScores : {};
    const taskFilter =
      typeof opts.task_filter === "function"
        ? opts.task_filter
        : (t) => isObj(t) && /avoid/i.test(t.name);
    const avoidSkillIds = TaskData.findAvoidSkillIds(tasksById, taskFilter);
    const scoredSkillIds = Object.getOwnPropertyNames(skillScores);
    const passFailIds = scoredSkillIds.reduce(
      (acc, cur) => {
        if (!avoidSkillIds.includes(cur)) {
          return acc;
        }
        // for AVOIDs, if player did the bad thing
        // on even ONE (out of possibly many) opportunities, then they fail
        const bucket =
          skillScores[cur].score_normalized < 1.0 ? acc.failed : acc.passed;
        bucket.push(cur);
        return acc;
      },
      { passed: [], failed: [] }
    );
    return {
      passed: _idsToNames(passFailIds.passed, skillsById),
      failed: _idsToNames(passFailIds.failed, skillsById),
    };
  };
}
export default Scoring;
