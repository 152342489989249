/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

/**
 * Navigation Button component that links another page when clicked
 *
 * @param props.isDisabled: button is disabled
 * @param props.text: text inside button
 * @param props.link: link that button goes to
 */
function NavButton(props) {
  const { classes } = props;
  let buttonType;
  let link = (
    <Link
      to={{
        pathname: props.link,
      }}
    >
      {props.text}
    </Link>
  );

  if (props.isDisabled) {
    buttonType = (
      <Button
        variant="contained"
        disabled
        classes={{
          root: classes.root,
          disabled: classes.disabled,
        }}
      >
        {link}
      </Button>
    );
  } else {
    buttonType = (
      <Button variant="contained" color="secondary">
        {link}
      </Button>
    );
  }

  return <MuiThemeProvider theme={theme}>{buttonType}</MuiThemeProvider>;
}

NavButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

const theme = createMuiTheme({
  palette: {
    primary: { main: "#1B6A9C" },
    secondary: { main: "#1178B9" },
  },
  typography: { useNextVariants: true },
});

const styles = {
  root: {
    "&$disabled": {
      background: "rgba(139, 157, 171, 0.3)",
      color: "white",
      boxShadow: "none",
    },
  },
  disabled: {},
};

export default withStyles(styles)(NavButton);
