/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { isIOS, isSafari } from "react-device-detect";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  ensureTaskAndPhrasingDataLoaded,
  loadData,
  logDialogue,
  logFeedback,
} from "actions";
import { setQuery } from "actions/query";
import HintsAndFeedback, {
  hintsAndFeedbackClear,
  hintsAndFeedbackUpdate,
} from "components/HintsAndFeedback";
import FeedbackIcons from "components/FeedbackIcons";
import HeaderBar from "components/HeaderBar";
import PopUp from "components/PopUp";
import SubtitleOptions from "components/SubtitleOptions";
import DialogData from "data/DialogData";

const HAS_NAVIGATOR = typeof navigator !== "undefined";
const IS_IPAD_PRO =
  HAS_NAVIGATOR &&
  navigator.platform === "MacIntel" &&
  navigator.maxTouchPoints > 1;
// video swapping and autoplay not working in safari on macOS or iPad (does work on iPhones)
const IS_SWAP_VIDEO_UNSUPPORTED = IS_IPAD_PRO || (isSafari && !isIOS);

class ScenarioPlayer extends Component {
  static propTypes = {
    data: PropTypes.object,
    didLoad: PropTypes.bool,
    id: PropTypes.string,
    dialog: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      subtitle: "",
      options: [],
      answer: 0,
      feedback: 0,
      video: null,
      idle: "idle_upright",

      isIdle: false,
      isIdleReady: false,
      isVideoReady: false,
      isScenarioFinished: false,
      shouldRedirect: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { dispatch } = this.props;

    dispatch(loadData(`/scenario_${id}/scenario_censored.xml`));
    dispatch(ensureTaskAndPhrasingDataLoaded());
    setQuery(this.props.location.search, dispatch);
  }

  componentDidUpdate() {
    if (!this.props.data || !this.props.didLoad) {
      return;
    }
    if (this.props.id === null) {
      const rootID = jsonpath.value(
        this.props.data,
        '$..DialogEntry[?(@.$.IsRoot=="true")]..ID'
      );
      this.setDialogue(rootID);
    }
  }

  setDialogue(id) {
    this.props.dispatch(logDialogue(id));
    const data = this.props.data;
    const feedback = jsonpath.value(
      data,
      `$..DialogEntry[?(@.$.ID==${id})]..Field[?(@.Title=="Tasks")].Value..*`
    );
    if (feedback) {
      feedback.split(", ").map((i) => this.props.dispatch(logFeedback(i)));
    }
    const dialogData = this.props.dialog_by_id[id];
    const video = DialogData.getDecision(dialogData);
    if (typeof video === "string" && video.trim().length > 0) {
      this.setState({
        video:
          `/scenario_${this.props.match.params.id}/${video}.mp4`.toLowerCase(),
        subtitle: DialogData.getText(dialogData),
        isIdle: false,
        isVideoReady: false,
      });
      return;
    }
    // we're in a node that has no video (Decision), so try to move forward till we find one
    const links = DialogData.getLinks(dialogData);
    if (!Array.isArray(links) || links.length === 0) {
      throw new Error(
        `no video or links out in dialog entry for id ${id}: ${JSON.stringify(
          dialogData
        )}`
      );
    }
    this.setDialogue(links[0]);
  }

  setOptions(id) {
    const dialogData = this.props.dialog_by_id[id];
    const links = DialogData.getLinks(dialogData);
    const options = this.shuffleArray(
      links.map((i) => {
        const optionData = this.props.dialog_by_id[i];
        const text = DialogData.getText(optionData);
        const color = DialogData.getFeedback(optionData);
        return { ID: i, feedback: color, text: text };
      })
    );
    this.setState({
      isIdle: true,
      isScenarioFinished: links.length === 0,
      options: options,
    });
  }

  async setIdle() {
    const optionData = this.props.dialog_by_id[this.state.answer];
    const idle = DialogData.getIdle(optionData) || this.state.idle;
    await new Promise((resolve) => setTimeout(resolve, "1000"));
    this.setState({
      idle: idle,
    });
  }

  onIdleReady = () => {
    this.setState({
      isIdleReady: true,
    });
  };

  onVideoReady = () => {
    this.setState({
      isVideoReady: true,
    });
  };

  onVideoStart = () => {
    this.setIdle();
  };

  onVideoEnded = () => {
    this.setState({
      feedback: 0,
      answer: 0,
    });
    this.setOptions(this.props.id);
    this.scrollToTop();
    hintsAndFeedbackUpdate(this.props);
  };

  onOptionSelected = (index, id, feedback) => {
    this.setState({
      feedback: feedback,
      answer: id,
    });
    this.setDialogue(id);
    this.moveOptionToTop(index);
    this.scrollToTop();
    hintsAndFeedbackClear();
  };

  onDismissPopup() {
    this.setState({
      shouldRedirect: true,
    });
  }

  scrollToTop() {
    const node = document.getElementById("scenario-player-content");
    if (node) {
      node.scrollTo(0, 0);
    }
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  moveOptionToTop(fromIndex) {
    if (fromIndex === 0) {
      return;
    }
    const toIndex = 0;
    let newArray = this.state.options.slice(0);
    newArray.splice(toIndex, 0, newArray.splice(fromIndex, 1)[0]);
    this.setState({ options: newArray });
  }

  renderIdle() {
    const node = document.getElementById("scenario-player-video");
    if (!node) {
      return;
    }
    const sid = this.props.match.params.id;
    const transformX =
      video_width > window.innerWidth
        ? (video_width - window.innerWidth) / 2
        : 0;
    const isTranslated =
      node.getBoundingClientRect().left === -transformX || transformX === 0;
    const isIdleVisible = this.state.isIdleReady && isTranslated;
    const idle_video = (
      <ReactPlayer
        className={`${isIdleVisible ? "visible" : "invisible"}`}
        url={`/scenario_${sid}/${this.state.idle}.mp4`}
        onReady={this.onIdleReady}
        loop={true}
        playing={true}
        playsinline={true}
        webkit-playsinline="true"
        volume={0}
        muted
      />
    );
    return idle_video;
  }

  renderVideo() {
    const transformX =
      video_width > window.innerWidth
        ? (video_width - window.innerWidth) / 2
        : 0;
    const isVideoVisible = !this.state.isIdle && this.state.isVideoReady;
    let video = (
      <ReactPlayer
        className={`video ${isVideoVisible ? "fadeIn" : "fadeOut"}`}
        url={this.state.video}
        onEnded={this.onVideoEnded}
        onReady={this.onVideoReady}
        onStart={this.onVideoStart}
        playing={true}
        playsinline={true}
        webkit-playsinline="true"
      />
    );

    if (IS_SWAP_VIDEO_UNSUPPORTED) {
      const sid = this.props.match.params.id;
      video = (
        <ReactPlayer
          url={
            this.state.isIdle
              ? `/scenario_${sid}/${this.state.idle}.mp4`
              : this.state.video
          }
          onStart={this.onVideoStart}
          onEnded={this.onVideoEnded}
          controls={!this.state.isIdle}
          loop={this.state.isIdle}
          muted={this.state.isIdle}
          playing={true}
          playsinline={true}
          webkit-playsinline="true"
        />
      );
    }

    return (
      <div
        id="scenario-player-video"
        style={{ transform: `translateX(-${transformX}px` }}
      >
        {IS_SWAP_VIDEO_UNSUPPORTED ? undefined : this.renderIdle()}
        {video}
      </div>
    );
  }

  render() {
    const { id } = this.props.match.params;
    const header = this.props.header_hidden ? undefined : (
      <HeaderBar text="Scenario Player" />
    );
    const width = Math.min(window.innerWidth, video_width);
    if (!this.props.didLoad) {
      return (
        <div className="flex-container">
          {header}
          <div className="expand">
            <CircularProgress className="spinner" />
          </div>
        </div>
      );
    }
    return (
      <div id="scenario-player-screen">
        <PopUp
          title="Scenario Complete"
          container="scenario-player-screen"
          isOpen={this.state.isScenarioFinished}
          onClose={() => this.onDismissPopup()}
        />
        {this.state.shouldRedirect ? (
          <Redirect to={`/${id}/followup${this.props.location.search}`} />
        ) : null}
        <div className="flex-container">
          {header}
          <div id="scenario-player-content" className="expand wrapper center">
            {this.renderVideo()}
            <FeedbackIcons feedback={this.state.feedback} />
            <HintsAndFeedback />
            <SubtitleOptions
              width={width}
              isIdle={this.state.isIdle}
              subtitle={this.state.subtitle}
              options={this.state.options}
              answer={this.state.answer}
              onOptionSelected={this.onOptionSelected}
            />
          </div>
        </div>
      </div>
    );
  }
}

const video_width = 640;

const mapStateToProps = (state) => {
  return {
    data: state.data,
    dialog: state.dialog,
    dialog_by_id: state.dialog_by_id,
    didLoad: state.didLoad,
    id: state.dialog_id,
    header_hidden: state.header_hidden,
    feedback_by_skill_id: state.feedback_by_skill_id,
    hints_by_skill_id: state.hints_by_skill_id,
    avoid_skill_ids: state.anti_skill_ids,
  };
};

export default connect(mapStateToProps)(ScenarioPlayer);
