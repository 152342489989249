/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ReportIcon from "@material-ui/icons/Report";
import InfoIcon from "@material-ui/icons/Info";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import WarningIcon from "@material-ui/icons/Warning";

const variantIcon = {
  mixed_feedback: WarningIcon,
  neg_feedback: WarningIcon,
  avoid: ReportIcon,
  hint: InfoIcon,
};

const semiTransparentBkg = (color) => {
  return color + "d0";
};

const useStyles1 = makeStyles((theme) => ({
  mixed_feedback: {
    backgroundColor: semiTransparentBkg(amber[700]),
    opacity: 0.7,
  },
  neg_feedback: {
    backgroundColor: semiTransparentBkg(theme.palette.error.dark),
    opacity: 0.7,
  },
  avoid: {
    backgroundColor: semiTransparentBkg(theme.palette.error.dark),
    opacity: 0.7,
  },
  hint: {
    backgroundColor: semiTransparentBkg(theme.palette.primary.dark),
    opacity: 0.7,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const styles = (theme) => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

function HintOrFeedbackSnackbarContent(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, hasNext, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          {hasNext && <NavigateNextIcon className={classes.icon} />}
          {!hasNext && <CloseIcon className={classes.icon} />}
        </IconButton>,
      ]}
      {...other}
    />
  );
}

HintOrFeedbackSnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([
    "success",
    "mixed_feedback",
    "neg_feedback",
    "avoid",
    "hint",
  ]).isRequired,
};

let updateExport;
let clearExport;

export const HintsAndFeedback = () => {
  const [messageQueue, setMessageQueue] = useState([]);
  const [open, setOpen] = useState(false);

  const hintsBySkillId = useSelector((state) => state.hints_by_skill_id);
  const feedbackBySkillId = useSelector((state) => state.feedback_by_skill_id);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const clearQueue = reason !== "timeout";
    hide(clearQueue);
  };

  const update = () => {
    rebuildMessageQueue();
  };

  const hide = (clearQueue = true) => {
    setOpen(false);
    if (clearQueue) {
      setMessageQueue([]);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const extractRandomMessage = (messagesById, variant, filter) => {
    if (!messagesById) {
      return null;
    }
    const messages = Object.getOwnPropertyNames(messagesById).reduce(
      (acc, cur) => {
        if (!filter || filter(messagesById[cur])) {
          acc.push({
            ...messagesById[cur],
            variant: variant,
          });
        }
        return acc;
      },
      []
    );
    switch (messages.length) {
      case 0:
        return null;
      case 1:
        return messages[0];
      default:
        shuffleArray(messages);
        return messages[0];
    }
  };

  const showNextMessage = () => {
    if (messageQueue.length < 2) {
      hide();
      return;
    }
    setMessageQueue(messageQueue.slice(1));
    setOpen(true);
  };

  /**
   * Generate a queue of 0-2 messages as follows:
   *   - if there are ANY avoids, add a random avoid
   *   - if there are NO avoids but there are ANY feedbacks, add a random feedback
   *   - if there are ANY hints, add a random hint
   */
  const rebuildMessageQueue = () => {
    const queue = [];
    const avoid = extractRandomMessage(
      feedbackBySkillId,
      "avoid",
      (m) => m.is_anti_skill
    );
    if (avoid && avoid.is_negative) {
      queue.push(avoid);
    } else {
      const feedback = extractRandomMessage(feedbackBySkillId, "feedback");
      if (feedback) {
        feedback.variant = feedback.is_negative
          ? "neg_feedback"
          : "mixed_feedback";
        queue.push(feedback);
      }
    }
    const hint = extractRandomMessage(hintsBySkillId, "hint");
    if (hint) {
      queue.push(hint);
    }
    setMessageQueue(queue);
    setOpen(queue.length > 0);
  };

  updateExport = update;
  clearExport = hide;

  const hasNext = messageQueue.length > 1;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={
        messageQueue.length > 0 &&
        messageQueue[0].variant === "hint" &&
        !hasNext
          ? null // if we're on the last hint, don't autohide
          : 5000 // otherwise autohide after 5 seconds
      }
      onClose={hasNext ? showNextMessage : handleClose}
      onExited={showNextMessage}
    >
      <HintOrFeedbackSnackbarContent
        key="hint-and-feedback-content"
        onClose={hasNext ? showNextMessage : handleClose}
        variant={messageQueue.length > 0 ? messageQueue[0].variant : "hint"}
        message={messageQueue.length ? messageQueue[0].message : ""}
        hasNext={hasNext}
      />
    </Snackbar>
  );
};

export function hintsAndFeedbackUpdate() {
  updateExport();
}

export function hintsAndFeedbackClear() {
  clearExport();
}

export default withStyles(styles)(HintsAndFeedback);
