/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import XMLData from "./XMLData";
import { isObj } from "./DataUtils";

const _addTask = (taskData, toTasksById, toSkillsById) => {
  const taskId = _addEntity(taskData, toTasksById);
  const task = toTasksById[taskId];
  task.skills = [];
  const skills = jsonpath.query(
    taskData,
    `$..Task[?(@.${XMLData.ATTRS}.isAssignable=='true')]`
  );
  skills.forEach((skillData) => {
    task.skills.push(_addSkill(skillData, taskId, toSkillsById));
  });
};

const _addSkill = (skillData, taskId, toSkillsById) => {
  const skillId = _addEntity(skillData, toSkillsById);
  const skill = toSkillsById[skillId];
  skill.task = taskId;
  return skillId;
};

const _addEntity = (entityData, toEntitiesById) => {
  const id = jsonpath.value(entityData, `$.${XMLData.ATTRS}.uuid`);
  toEntitiesById[id] = {
    name: jsonpath.value(entityData, `$.${XMLData.ATTRS}.name`),
    description: jsonpath.value(entityData, `$.description.*`),
  };
  return id;
};

class TaskData {
  /**
   * Convert task xml
   * to a normalized form of tasks (by id) and skills (by id)
   */

  static async fromXML(xml) {
    const data = await XMLData.toJson(
      xml
        .toString()
        .replace(/edu.usc.cct.learn.ped.representation.TaskList/g, "TaskList")
    );
    const tasks = jsonpath.query(
      data,
      `$.TaskList.tasks[*].Task[?(@.${XMLData.ATTRS}.isAssignable=='false')]`
    );
    const result = tasks.reduce(
      (acc, cur) => {
        _addTask(cur, acc.tasks, acc.skills);
        return acc;
      },
      { tasks: {}, skills: {} }
    );
    result.anti_skill_ids = TaskData.findAvoidSkillIds(result.tasks);
    return result;
  }

  /**
   * Find the list of skill ids belonging to tasks that match a given filter
   * @param {object} tasksById dict of task data by task id
   * @param {function(taskData)} taskFilter returns TRUE if taskData matches the filter
   */
  static findSkillIdsBelongingToTasks(tasksById, taskFilter) {
    const taskIds = Object.getOwnPropertyNames(tasksById);
    return taskIds.reduce((acc, cur) => {
      if (typeof taskFilter !== "function" || taskFilter(tasksById[cur])) {
        const curSkills = Array.isArray(tasksById[cur].skills)
          ? tasksById[cur].skills
          : [];
        return [...acc, ...curSkills];
      }
      return acc;
    }, []);
  }

  /**
   * Find the list of skill ids that player is supposed to avoid.
   * By convention/default these are skills belonging to a task with name 'avoid'
   *
   * @param {object} tasksById dict of task data by task id
   * @param {function(taskData)} [avoidTaskFilter] default is "task.name matches 'avoid'"
   */
  static findAvoidSkillIds(tasksById, avoidTaskFilter) {
    return TaskData.findSkillIdsBelongingToTasks(
      tasksById,
      typeof avoidTaskFilter === "function"
        ? avoidTaskFilter
        : (t) => isObj(t) && /avoid/i.test(t.name)
    );
  }
}
export default TaskData;
