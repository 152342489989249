/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import React, { Component } from "react";
import { connect } from "react-redux";
// import { submitResultsAndTerminate } from "actions";
// import Scoring from "data/Scoring";
import { isObj } from "data/DataUtils";
import { cmi5_instance } from "../actions/query";

// const { terminate } = actions;
/**
 * Outro screen calls terminate on cmi5 session,
 * which triggers PAL (or whatever container LMS)
 * to close this assignable unit.
 * An alternative to having this screen,
 * would be to just call terminate from the
 * FINISH button onClick on the final screen
 */
class Exit extends Component {
  componentDidMount() {
    const score_data = this.props.score_data;
    if (!isObj(score_data)) {
      console.warn(`submit cmi5 result finds not scores stored in state!`);
    }
    const scoreData = isObj(score_data) ? score_data : { score_normalized: 0 };
    const kcScores = this.props.kc_scores;
    if (!Array.isArray(kcScores)) {
      console.warn(`submit cmi5 result has no kc_scores in state`);
    }
    if (isNaN(Number(scoreData.score_normalized))) {
      console.error("no score in score_data: ", scoreData);
    }
    const score = !isNaN(scoreData.score_normalized)
      ? scoreData.score_normalized
      : 0;

    if (cmi5_instance) {
      console.warn("send cmi5 score");
      cmi5_instance.moveOn({ score: score });
    }

    // const { dispatch } = this.props;
    // dispatch(submitResultsAndTerminate());
  }

  render() {
    return (
      <div>
        Exiting... Please wait while your score is being logged. This process
        may take several seconds.
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    score_data: state.score_data,
    kc_scores: state.kc_scores,
    cmi5_status: state.cmi5_status,
  };
};

export default connect(mapStateToProps)(Exit);
