/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import { reducers as cmi5Reducer } from "redux-cmi5";
import {
  REQUEST_DATA,
  SCENARIO_DATA_LOAD_SUCCEEDED,
  ADD_DIALOGUE,
  ADD_FEEDBACK,
  SKILL_PHRASING_DATA_LOAD_REQUESTED,
  SKILL_PHRASING_DATA_LOAD_FAILED,
  SKILL_PHRASING_DATA_LOAD_SUCCEEDED,
  TASK_DATA_LOAD_REQUESTED,
  TASK_DATA_LOAD_FAILED,
  TASK_DATA_LOAD_SUCCEEDED,
  SET_HEADER_HIDDEN,
} from "actions";
import Scoring from "data/Scoring";

const initialState = cmi5Reducer({
  header_hidden: false,
  data_url: null,
  data: null,
  didLoad: false,
  dialog_by_id: {},
  dialog_id: null, // current dialog id
  dialog: [], // history of dialog ids
  feedback: [],
  pos_feedback: [],
  neg_feedback: [],
  skills_by_id: {},
  tasks_by_id: {},
  tasks_loaded: false,
  anti_skill_ids: [],
  skill_phrasings_loaded: false,
  feedback_by_skill_id: {},
  hints_by_skill_id: {},
});

const rootReducer = (state = initialState, action) => {
  state = cmi5Reducer(state, action);
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        didLoad: false,
        data_url: action.url,
      };
    case SCENARIO_DATA_LOAD_SUCCEEDED:
      return {
        ...state,
        didLoad: true,
        data: action.result.data,
        dialog_by_id: action.result.dialogs,
        data_url: action.url,
      };
    case TASK_DATA_LOAD_REQUESTED:
      return {
        ...state,
        tasks_load_in_progress: true,
      };
    case TASK_DATA_LOAD_FAILED:
      return {
        ...state,
        tasks_load_in_progress: false,
      };
    case TASK_DATA_LOAD_SUCCEEDED:
      return {
        ...state,
        skills_by_id: action.data.skills,
        tasks_by_id: action.data.tasks,
        anti_skill_ids: Array.isArray(action.data.anti_skill_ids)
          ? action.data.anti_skill_ids
          : [],
        tasks_loaded: true,
        tasks_load_in_progress: false,
      };
    case SKILL_PHRASING_DATA_LOAD_REQUESTED:
      return {
        ...state,
        skill_phrasings_load_in_progress: true,
      };
    case SKILL_PHRASING_DATA_LOAD_FAILED:
      return {
        ...state,
        skill_phrasings_load_in_progress: false,
      };
    case SKILL_PHRASING_DATA_LOAD_SUCCEEDED:
      return {
        ...state,
        skill_phrasings_by_id: action.data,
        skill_phrasings_loaded: true,
        skill_phrasings_load_in_progress: false,
      };
    case ADD_DIALOGUE:
      let score_data, kc_scores, aar_skills, aar_avoids, hints_and_feedback;
      if (state.tasks_loaded) {
        score_data = Scoring.scoreChoicePath(
          [...state.dialog, action.id],
          state.dialog_by_id,
          state.tasks_by_id
        );
        // if (and only if) the player got this answer wrong
        // we give feedback and hints
        kc_scores = Scoring.kcScoresFromSkillScores(
          score_data.scores_by_skill,
          state.skills_by_id,
          state.tasks_by_id
        );
        aar_skills = Scoring.aarSkillsBreakdownFromSkillScores(
          score_data.scores_by_skill,
          state.skills_by_id,
          state.tasks_by_id
        );
        aar_avoids = Scoring.aarAvoidsBreakdownFromSkillScores(
          score_data.scores_by_skill,
          state.skills_by_id,
          state.tasks_by_id
        );
        const curHintsAndFeedback = {
          feedback_by_skill_id: state.feedback_by_skill_id,
          hints_by_skill_id: state.hints_by_skill_id,
        };
        hints_and_feedback = state.skill_phrasings_loaded
          ? Scoring.updateHintsAndFeedback(
              curHintsAndFeedback,
              action.id,
              state.dialog_by_id,
              state.skill_phrasings_by_id,
              state.anti_skill_ids
            )
          : curHintsAndFeedback;
      }
      hints_and_feedback = hints_and_feedback || {
        feedback_by_skill_id: {},
        hints_by_skill_id: {},
      };
      return {
        ...state,
        dialog: [...state.dialog, action.id],
        dialog_id: action.id,
        score_data,
        kc_scores,
        aar_skills,
        aar_avoids,
        ...hints_and_feedback,
      };
    case ADD_FEEDBACK:
      if (action.feedback === "(+)") {
        return {
          ...state,
          feedback: [...state.feedback, action.id],
          pos_feedback: [...state.pos_feedback, action.id],
        };
      } else if (action.feedback === "(-)") {
        return {
          ...state,
          feedback: [...state.feedback, action.id],
          neg_feedback: [...state.neg_feedback, action.id],
        };
      } else {
        return {
          ...state,
          feedback: [...state.feedback, action.id],
        };
      }
    case SET_HEADER_HIDDEN:
      return {
        ...state,
        header_hidden: action.hidden,
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  const s = rootReducer(state, action);
  return s;
};
