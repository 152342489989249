/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 

No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import jsonpath from "jsonpath";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { loadData } from "actions";
import { setQuery } from "actions/query";
import AnswerButton from "components/AnswerButton";
import HeaderBar from "components/HeaderBar";
import MediaCard from "components/MediaCard";
import NavButton from "components/NavButton";
import PopUp from "components/PopUp";

class FollowUp extends Component {
  static propTypes = {
    data: PropTypes.object,
    didLoad: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      answer: 0,
      feedback: "",
      shouldViewFeedback: false,
    };
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadData(`/inots_counselingscenarioinfo.xml`));
    setQuery(this.props.location.search, dispatch);
  }

  onOptionSelected(i, feedback) {
    this.setState({
      shouldViewFeedback: true,
      answer: i,
      feedback: feedback,
    });
  }

  onDismissPopup() {
    this.setState({
      shouldViewFeedback: false,
    });
  }

  renderDebrief() {
    let content;
    if (this.props.didLoad && this.props.data) {
      content = (
        <div>
          {jsonpath.value(this.props.data, "$..PREAMBLE")}
          <List>
            {jsonpath.value(this.props.data, "$..BULLET").map((bullet, i) => (
              <ListItem key={`debrief-${i}`}>
                <Typography variant="body1">{bullet}</Typography>
              </ListItem>
            ))}
          </List>
        </div>
      );
    }

    return <MediaCard title="Debrief:" content={content} />;
  }

  renderQuiz() {
    let content;
    if (this.props.didLoad && this.props.data) {
      content = (
        <div>
          {jsonpath.value(this.props.data, "$..QUESTION")}
          <List component="nav">
            {jsonpath.value(this.props.data, "$..CHOICE").map((choice, i) => (
              <Typography variant="body1" key={`quiz-option-${i}`}>
                {this.renderOption(
                  jsonpath.value(choice, "$..ID"),
                  choice._,
                  jsonpath.value(choice, "$..FEEDBACK")
                )}
              </Typography>
            ))}
          </List>
        </div>
      );
    }

    return <MediaCard title="Quiz:" content={content} />;
  }

  renderOption(i, txt, feedback) {
    const isDisabled = this.state.answer !== 0;
    const isSelected = this.state.answer === i;

    return (
      <ListItem>
        <AnswerButton
          text={txt}
          isDisabled={isDisabled}
          isSelected={isSelected}
          onClick={() => this.onOptionSelected(i, feedback)}
        />
      </ListItem>
    );
  }

  render() {
    const { id } = this.props.match.params;
    const header = this.props.header_hidden ? undefined : (
      <HeaderBar text="Follow Up" />
    );

    if (!this.props.didLoad) {
      return (
        <div className="flex-container">
          {header}
          <div className="expand">
            <CircularProgress className="spinner" />
          </div>
        </div>
      );
    }

    return (
      <div>
        <PopUp
          title="Feedback"
          text={this.state.feedback}
          isOpen={this.state.shouldViewFeedback}
          onClose={() => this.onDismissPopup()}
        />
        <div className="flex-container">
          {header}
          <div className="content expand">
            <List>
              <ListItem key="debrief-list">{this.renderDebrief()}</ListItem>
              <ListItem key="quiz-options">{this.renderQuiz()}</ListItem>
            </List>
          </div>
          <div className="footer">
            <NavButton
              text="Continue"
              link={`/${id}/aar${this.props.location.search}`}
              isDisabled={!this.state.answer}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    data: state.data
      ? jsonpath.value(state.data, `$..SCENARIO[?(@.$.ID==${id})]`)
      : state.data,
    didLoad: state.didLoad,
    header_hidden: state.header_hidden,
  };
};

export default connect(mapStateToProps)(FollowUp);
